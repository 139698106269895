import {useEffect, useRef} from "react";
import React from "react";
import { DoubleSide } from "three";
import {scalePosition, scaleSize} from "../utils";
interface IProps {
  size: [number, number, number];
}

type Position = [number, number, number];

interface Side {
  position: Position;
  size: Position;
}

const position: Position = [0, 0, 0];
const Truck = ({ size }: IProps) => {
  const ref = useRef();
  const front: any = {
    position: [position[0], 0, position[2] + size[2] / 2 + 50],
    size: [size[0], 100, 100],
  };
  const bottomSide: Side = {
    position: position,
    size: [size[0], size[2], size[1]],
  };
  const frontSide: Side = {
    position: [position[0], position[1] + size[1] / 2, position[2] + size[2] / 2],
    size: [size[0], size[1], size[2]],
  };
  const rightSide: Side = {
    position: [position[0] - size[0] / 2, position[1] + size[1] / 2, position[2]],
    size: [size[2], size[1], size[0]],
  };
  const leftSide: Side = {
    position: [position[0] + size[0] / 2, position[1] + size[1] / 2, position[2]],
    size: [size[2], size[1], size[0]],
  };
  return (
    <group position={position}>
      <mesh ref={ref} position={scalePosition(front.position)} rotation-x={-Math.PI / 2}>
        <planeBufferGeometry attach="geometry" args={scaleSize(front.size)} />
        <meshStandardMaterial flatShading={true} attach="material" color={"green"} />
      </mesh>
      <mesh ref={ref} position={scalePosition(frontSide.position)} rotation-y={-Math.PI}>
        <planeBufferGeometry attach="geometry" args={scaleSize(frontSide.size)} />
        <meshStandardMaterial
          flatShading={true}
          attach="material"
          color={"green"}
          side={DoubleSide}
          transparent={true}
          opacity={0.5}
        />
      </mesh>
      <mesh ref={ref} position={scalePosition(rightSide.position)} rotation-y={-Math.PI / 2}>
        <planeBufferGeometry attach="geometry" args={scaleSize(rightSide.size)} />
        <meshPhysicalMaterial wireframe={true} attach="material" color={"black"} />
      </mesh>

      <mesh ref={ref} position={scalePosition(leftSide.position)} rotation-y={-Math.PI / 2}>
        <planeBufferGeometry attach="geometry" args={scaleSize(leftSide.size)} />
        <meshPhysicalMaterial wireframe={true} attach="material" color={"black"} />
      </mesh>
      <mesh ref={ref} rotation-x={-Math.PI / 2}>
        <planeBufferGeometry attach="geometry" args={scaleSize(bottomSide.size)} />
        <meshLambertMaterial attach="material" color={"black"} />
      </mesh>
    </group>
  );
};

export default Truck
