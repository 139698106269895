import React, {useRef, useState} from "react";
import {
    IPackage,
    defaultPackages,
    trucks,
} from "./utils";
import {useInterval} from "react-use";
import ThreeDView from "./3DView";

const TRUCK = trucks[2]

interface ViewData {
    packages: IPackage[],
    truckSize: [number, number, number]
}

const HEIGHT=600;

const Banner3DView = () => {
    const wrapper = useRef<HTMLDivElement>(null);
    const [timer, setTimer] = useState<number>(0);
    useInterval(
        () => {
            setTimer(timer + 1);
        },
        timer < defaultPackages.length ? 500 : null
    );
    const getViewData = (): ViewData => {
        const defaultTruckSize = TRUCK.size;
        return {
            packages: defaultPackages.slice(0, timer),
            truckSize: defaultTruckSize
        }
    }
    const {packages, truckSize} = getViewData();
    return (
        <div style={{height: `${HEIGHT}px`}} ref={wrapper}>
            <ThreeDView packages={packages} truckSize={truckSize} />
        </div>
    );
};
export default Banner3DView;
