import * as React from 'react';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useI18next} from "gatsby-plugin-react-i18next";
import * as styles from './PrivacyPolicy.module.scss';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

interface Props {
    open: boolean;
    onClose: () => void;
}

export default function PrivacyPolicy({open, onClose}: Props) {
    const {language} = useI18next();
    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            {language === 'pl' && <>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Polityka prywatności
                </BootstrapDialogTitle>
                <DialogContent dividers className={styles.Content}>
                    <h3>1. Informacje ogólne</h3>
                    <ol>
                        <li>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: <strong>loggy.pl</strong>
                        </li>
                        <li>Operatorem serwisu oraz Administratorem danych osobowych jest: Loggy sp. z o. o., ul.
                            Świeradowska 47, 02-662 Warszawa, KRS 0000802199, NIP: 521-387-55-58, REGON 384286533
                        </li>

                        <li>Adres kontaktowy poczty elektronicznej operatora: biuro@loggy.pl</li>

                        <li>Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych
                            dobrowolnie w Serwisie.
                        </li>
                        <li>Serwis wykorzystuje dane osobowe w następujących celach:</li>
                        <ul>
                            <li>Prowadzenie newslettera</li>
                            <li>Obsługa zapytań przez formularz</li>
                        </ul>
                        <li>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w
                            następujący sposób:
                            <ol>
                                <li>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone
                                    do systemów Operatora.
                                </li>
                                <li>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
                                    „ciasteczka”).
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <h3>2. Wybrane metody ochrony danych stosowane przez
                        Operatora</h3>
                    <ol>
                        <li>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji
                            (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie,
                            zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym
                            serwerze.
                        </li>
                        <li>Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania,
                            wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczególności
                            oznacza regularne aktualizacje komponentów programistycznych.
                        </li>
                    </ol>
                    <h3>3. Hosting</h3>
                    <ol>
                        <li>Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: OVH</li>
                    </ol>
                    <h3>4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania
                        danych</h3>
                    <ol>
                        <li>W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym
                            odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do
                            zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:
                            <ul>
                                <li>osoby upoważnione przez nas, pracownicy i współpracownicy, którzy muszą mieć
                                    dostęp do danych osobowych w celu wykonywania swoich obowiązków,
                                </li>
                                <li>firma hostingowa,</li>
                                <li>firmy obsługująca mailingi,</li>
                                <li>firmy obsługująca komunikaty SMS,</li>
                                <li>firmy, z którymi Administrator współpracuje w zakresie marketingu własnego,</li>
                                <li>kurierzy,</li>
                                <li>ubezpieczyciele,</li>
                                <li>kancelarie prawne i windykatorzy,</li>
                                <li>banki,</li>
                                <li>operatorzy płatności,</li>
                                <li>organy publiczne.</li>
                            </ul>
                        </li>
                        <li>Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne
                            do wykonania związanych z nimi czynności określonych osobnymi przepisami (np. o
                            prowadzeniu rachunkowości). W odniesieniu do danych marketingowych dane nie będą
                            przetwarzane dłużej niż przez 3 lata.
                        </li>
                        <li>Przysługuje Ci prawo żądania od Administratora:
                            <ul>
                                <li>dostępu do danych osobowych Ciebie dotyczących,</li>
                                <li>ich sprostowania,</li>
                                <li>usunięcia,</li>
                                <li>ograniczenia przetwarzania,</li>
                                <li>oraz przenoszenia danych.</li>
                            </ul>
                        </li>
                        <li>Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.3
                            c) wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów
                            realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie
                            będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do
                            przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności
                            ustalenia, dochodzenia lub obrony roszczeń.
                        </li>
                        <li>Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych
                            Osobowych, ul. Stawki 2, 00-193 Warszawa.
                        </li>
                        <li>Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.</li>
                        <li>W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym
                            podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej
                            umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.
                        </li>
                        <li>Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie
                            danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.
                        </li>
                    </ol>
                    <h3>5. Informacje w formularzach</h3>
                    <ol>
                        <li>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile
                            zostaną one podane.
                        </li>
                        <li>Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).
                        </li>
                        <li>Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w
                            formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres
                            e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.
                        </li>
                        <li>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego
                            formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu
                            handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny
                            sposób informuje, do czego on służy.
                        </li>
                    </ol>

                    <h3>6. Logi Administratora</h3>
                    <ol>
                        <li>Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są
                            wykorzystywane w celu administrowania serwisem.
                        </li>
                    </ol>
                    <h3>7. Istotne techniki marketingowe</h3>
                    <ol>
                        <li>Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics (Google
                            Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych
                            osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu
                            ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach
                            użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i
                            edytować informacje wynikające z plików cookies przy pomocy narzędzia:
                            https://www.google.com/ads/preferences/
                        </li>
                    </ol>

                    <h3>8. Informacja o plikach cookies</h3>
                    <ol>
                        <li>Serwis korzysta z plików cookies.</li>
                        <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki
                            tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i
                            przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj
                            zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na
                            urządzeniu końcowym oraz unikalny numer.
                        </li>
                        <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz
                            uzyskującym do nich dostęp jest operator Serwisu.
                        </li>
                        <li>Pliki cookies wykorzystywane są w następujących celach:
                            <ol>
                                <li>utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik
                                    nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;
                                </li>
                                <li>realizacji celów określonych powyżej w części "Istotne techniki marketingowe";
                                </li>
                            </ol>
                        </li>
                        <li>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session
                            cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi,
                            które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania,
                            opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki
                            internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika
                            przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez
                            Użytkownika.
                        </li>
                        <li>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj
                            domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika.
                            Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie.&nbsp;Przeglądarka
                            internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne
                            blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub
                            dokumentacja przeglądarki internetowej.
                        </li>
                        <li>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne
                            na stronach internetowych Serwisu.
                        </li>
                        <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą
                            być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy
                            to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w
                            USA), Twitter (Twitter Inc. z siedzibą w USA).
                        </li>
                    </ol>
                    <h3>9. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać
                        zgodę?</h3>
                    <ol>
                        <li>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia
                            przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów
                            uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może
                            utrudnić,&nbsp;a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www
                        </li>
                        <li>W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową,
                            której używasz i postępuj zgodnie z instrukcjami:
                            <ul>
                                <li><a
                                    href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">Edge</a>
                                </li>
                                <li><a
                                    href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet
                                    Explorer</a></li>
                                <li><a
                                    href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Chrome</a>
                                </li>
                                <li><a href="http://support.apple.com/kb/PH5042">Safari</a></li>
                                <li><a
                                    href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Firefox</a>
                                </li>
                                <li><a href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera</a></li>
                            </ul>
                            <p>Urządzenia mobilne:</p>
                            <ul>
                                <li><a
                                    href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Android</a>
                                </li>
                                <li><a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">Safari (iOS)</a>
                                </li>
                                <li><a
                                    href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">Windows
                                    Phone</a></li>
                            </ul>
                        </li>
                    </ol>
                </DialogContent>
            </>}
            {language !== 'pl' && <>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Privacy policy
                </BootstrapDialogTitle>
                <DialogContent dividers className={styles.Content}>
                    <h3>1. General
                        information</h3>
                    <ol>
                        <li>This policy
                            applies to the website operating at the url: <strong>loggy.pl</strong></li>
                        <li>The website
                            operator and the personal data administrator is: Loggy sp. Z oo, ul. Świeradowska 47, 02-662 Warsaw, KRS 0000802199, NIP:
                            521-387-55-58, REGON 384286533</li>
                        <li>The operator's
                            e-mail contact address: biuro@loggy.pl</li>
                        <li>The operator
                            is the Administrator of your personal data in relation to the data provided voluntarily on
                            the Website.</li>
                        <li>The website
                            uses personal data for the following purposes:</li>
                        <ul>
                            <li>Keeping
                                the newsletter</li>
                            <li>Handling
                                inquiries via the form</li>
                        </ul>
                        <li>The website
                            obtains information about users and their behavior in the following way:
                            <ol>
                                <li>Through
                                    data entered voluntarily in forms, which are entered into the Operator's
                                    systems.</li>
                                <li>By
                                    saving cookie files in end devices (so-called "cookies").</li>
                            </ol>
                        </li>
                    </ol>
                    <h3>2. Selected data
                        protection methods used by the Operator</h3>
                    <ol>
                        <li>The places of
                            logging in and entering personal data are protected in the transmission layer (SSL
                            certificate). As a result, personal data and
                            login data entered on the website are encrypted on the user's computer and can only be read
                            on the target server.</li>
                        <li>An important
                            element of data protection is regular updating of all software used by the Operator to
                            process personal data, which in particular means regular updates of programming
                            components.</li>
                    </ol>
                    <h3>3. Hosting</h3>
                    <ol>
                        <li>The website is
                            hosted (technically maintained) on the operator's servers: OVH</li>
                    </ol>
                    <h3>4. Your rights and
                        additional information on how to use the data</h3>
                    <ol>
                        <li>In some
                            situations, the Administrator has the right to transfer your personal data to other
                            recipients if it is necessary to perform the contract concluded with you or to fulfill the
                            obligations incumbent on the Administrator. This
                            applies to such groups of recipients:
                            <ul>
                                <li>persons
                                    authorized by us, employees and associates who must have access to personal data in
                                    order to perform their duties,</li>
                                <li>hosting
                                    company,</li>
                                <li>mailing
                                    companies,</li>
                                <li>companies
                                    handling SMS messages,</li>
                                <li>companies
                                    with which the Administrator cooperates in the field of own marketing,
                                </li>
                                <li>couriers,</li>
                                <li>insurers,</li>
                                <li>law
                                    firms and debt collectors,</li>
                                <li>banks,</li>
                                <li>payment
                                    operators,</li>
                                <li>public
                                    authorities.</li>
                            </ul>
                        </li>
                        <li>Your personal
                            data processed by the Administrator for no longer than it is necessary to perform the
                            related activities specified in separate regulations (e.g. on accounting). With regard to marketing data, the data will not be
                            processed for more than 3 years.</li>
                        <li>You have the
                            right to request from the Administrator:
                            <ul>
                                <li>access
                                    to your personal data,</li>
                                <li>rectifying
                                    them,</li>
                                <li>deletion,</li>
                                <li>processing
                                    restrictions,</li>
                                <li>and
                                    data portability.</li>
                            </ul>
                        </li>
                        <li>You have the
                            right to object to the processing indicated in point 3.3 c) to the processing of personal
                            data in order to perform the legitimate interests pursued by the Administrator, including
                            profiling, while the right to object may not be exercised if there are valid legally
                            justified grounds for processing of your interests, rights and freedoms, in particular
                            establishing, investigating or defending claims.</li>
                        <li>The
                            Administrator's actions may be appealed against to the President of the Personal Data
                            Protection Office, ul. Stawki 2, 00-193
                            Warsaw.</li>
                        <li>Providing
                            personal data is voluntary, but necessary to operate the Website.</li>
                        <li>In relation to
                            you, actions may be taken consisting in automated decision making, including profiling to
                            provide services under the concluded contract and for the purpose of conducting direct
                            marketing by the Administrator.</li>
                        <li>Personal data
                            is not transferred from third countries within the meaning of the provisions on the
                            protection of personal data. This means that
                            we do not send them outside the European Union.</li>
                    </ol>
                    <h3>5. Information in
                        the forms</h3>
                    <ol>
                        <li>The website
                            collects information provided voluntarily by the user, including personal data, if
                            provided.</li>
                        <li>The website
                            may save information about connection parameters (time stamp, IP address).
                        </li>
                        <li>The website,
                            in some cases, may save information facilitating the linking of data in the form with the
                            e-mail address of the user filling in the form. In this case, the user's e-mail address appears inside the
                            url of the page containing the form.</li>
                        <li>The data
                            provided in the form is processed for the purpose resulting from the function of a specific
                            form, eg to process the service request or commercial contact, service registration, etc.
                            Each time the context and description of the form clearly informs what it is used
                            for.</li>
                    </ol>
                    <h3>6. Administrator
                        logs</h3>
                    <ol>
                        <li>Information on
                            the behavior of users on the website may be subject to logging. These data are used to administer the
                            website.</li>
                    </ol>
                    <h3>7. Relevant
                        marketing techniques</h3>
                    <ol>
                        <li>The operator
                            uses statistical analysis of website traffic through Google Analytics (Google Inc. based in
                            the USA). The operator does not provide
                            personal data to the operator of this service, but only anonymised information. The service is based on the use of cookies on the user's
                            end device. In terms of information about user
                            preferences collected by the Google advertising network, the user can view and edit
                            information derived from cookies using the tool:
                            https://www.google.com/ads/preferences/</li>
                    </ol>
                    <h3>8. Information
                        about cookies</h3>
                    <ol>
                        <li>The website
                            uses cookies.</li>
                        <li>Cookie files
                            (so-called "cookies") are IT data, in particular text files, which are stored on the Website
                            User's end device and are intended for using the Website's pages. Cookies usually contain the name of the website they come
                            from, the storage time on the end device and a unique number.</li>
                        <li>The entity
                            that places cookies on the Website User's end device and obtains access to them is the
                            Website operator.</li>
                        <li>Cookies are
                            used for the following purposes:
                            <ol>
                                <li>maintaining
                                    the Website user's session (after logging in), thanks to which the user does not
                                    have to re-enter the login and password on every subpage of the
                                    Website;</li>
                                <li>achieving
                                    the goals set out above in the section "Important marketing
                                    techniques";</li>
                            </ol>
                        </li>
                        <li>The Website
                            uses two basic types of cookies: session cookies and persistent cookies. Session cookies are temporary files that are stored on the
                            User's end device until logging out, leaving the website or turning off the software (web
                            browser). Persistent cookies are stored on the
                            User's end device for the time specified in the cookie file parameters or until they are
                            deleted by the User.</li>
                        <li>Software for
                            browsing websites (web browser) usually allows cookies to be stored on the User's end device
                            by default. Website users can change the
                            settings in this regard. The web browser
                            allows you to delete cookies. It is also
                            possible to automatically block cookies.Detailed information on this subject can be found in
                            the help or documentation of the web browser.</li>
                        <li>Restrictions
                            on the use of cookies may affect some of the functionalities available on the Website
                            pages.</li>
                        <li>Cookies placed
                            on the Website User's end device may also be used by entities cooperating with the Website
                            operator, in particular the following companies: Google (Google Inc. based in the USA),
                            Facebook (Facebook Inc. based in the USA), Twitter (Twitter Inc. based in the
                            USA).</li>
                    </ol>
                    <h3>9. Managing
                        cookies - how to express and withdraw consent in practice?</h3>
                    <ol>
                        <li>If the user
                            does not want to receive cookies, he may change the browser settings. We reserve that disabling cookies necessary for
                            authentication processes, security, maintaining user preferences may make it difficult, and
                            in extreme cases may prevent the use of websites.</li>
                        <li>In order to
                            manage cookie settings, select the web browser you use from the list below and follow the
                            instructions:
                            <ul>
                                <li><a
                                    href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">Edge</a>
                                </li>
                                <li><a
                                    href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet
                                    Explorer</a></li>
                                <li><a
                                    href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Chrome</a>
                                </li>
                                <li><a href="http://support.apple.com/kb/PH5042">Safari</a></li>
                                <li><a
                                    href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Firefox</a>
                                </li>
                                <li><a href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera</a></li>
                            </ul>
                            <p>Mobile
                                devices:</p>
                            <ul>
                                <li><a
                                    href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Android</a>
                                </li>
                                <li><a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">Safari (iOS)</a></li>
                                <li><a
                                    href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">Windows
                                    Phone</a></li>
                            </ul>
                        </li>
                    </ol>
                </DialogContent>

            </>}
        </BootstrapDialog>
    );
};
