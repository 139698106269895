import * as React from "react"
import cx from "classnames";
import {Button, Container, Grid} from "@mui/material";
import * as styles from "./Footer.module.scss"
import {useState} from "react";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../PirvacyPolicy/PrivacyPolicy";
import {useI18next} from "gatsby-plugin-react-i18next";

const Footer = () => {
    const {t} = useI18next();
    const [termsAndConditionsVisible, setTermsAndConditionsVisible] = useState<boolean>(false);
    const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState<boolean>(false);

    const onTermsAndConditionsClick = (event: any) => {
        setTermsAndConditionsVisible(true);
    }
    const onPrivacyPolicyClick = (event: any) => {
        setPrivacyPolicyVisible(true);
    }

    return (
        <>
            <TermsAndConditions open={termsAndConditionsVisible} onClose={() => setTermsAndConditionsVisible(false)}/>
            <PrivacyPolicy open={privacyPolicyVisible} onClose={() => setPrivacyPolicyVisible(false)}/>
            <Container maxWidth={false} className={cx(styles.Footer)}>
                <Container maxWidth={'lg'}>
                    <Grid container>
                        <Grid item xs={2}>
                            <div className={styles.Logo}>Loggy</div>
                        </Grid>
                        <Grid item xs={10} className={styles.FooterNav}>
                            <ul>
                                <li><Button className={styles.FooterButton} onClick={onTermsAndConditionsClick}
                                            size={'small'} variant={'text'}
                                            type={'button'}>{t("footer.termsOfUse")}</Button></li>
                                <li><Button className={styles.FooterButton} onClick={onPrivacyPolicyClick}
                                            size={'small'} variant={'text'}
                                            type={'button'}>{t("footer.privacyPolicy")}</Button></li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <div className={styles.FooterBottom}>
                <Container maxWidth={'lg'}>
                    Loggy {new Date().getFullYear()} &copy;
                </Container>
            </div>
        </>
    )
}

export default Footer;
