export interface IPackage {
    size: [number, number, number];
    position: [number, number, number];
    id: string;
    type: string;
    weight: number;
    name: string;
    rotation?: number;
    switchedSize?: boolean;
}

export const colors = [
    "#5fad41",
    "#2d936c",
    "#CCFBFE",
    "#391463",
    "#3a0842",
    "#545775",
    "#FBACBE",
    "#0c0a3e",
    "#7b1e7a",
    "#A18276",
    "#E6FAFC",
    "#9CFC97",
    "#353D2F",
    "#96C0B7",
    "#FEF6C9",
    "#6BA368",
];

export const getColor = (packageTypeId: string, packages: IPackage[]) => {
    const types = [...new Set(packages.map((p) => p.type))];
    const typeIndex = types.findIndex((type) => type === packageTypeId);
    return colors[typeIndex];
};

export const degreesToRadians = (degrees = 0) => {
    const pi = Math.PI;
    return degrees * (pi / 180);
};

export const getRotationProperties = (rotation: number) => {
    return [0, degreesToRadians(rotation), 0, "XYZ"];
};

export interface ITruck {
    type: string;
    name: string;
    size: [number, number, number];
    capacity: number;
}

export const TRUCK_POSITION: [number, number, number] = [0, 0, 0];
export const trucks: ITruck[] = [
    {
        type: "car",
        name: "bus8EP",
        capacity: 1000,
        size: [220, 220, 420],
    },
    {
        type: "car",
        name: "bus10EP",
        capacity: 1100,
        size: [220, 230, 490],
    },
    {
        type: "car",
        name: "bus12EP",
        capacity: 1500,
        size: [245, 230, 490],
    },
    {
        type: "car",
        name: "solo15EP",
        capacity: 6000,
        size: [245, 240, 620],
    },
    {
        type: "car",
        name: "solo18EP",
        capacity: 8000,
        size: [245, 240, 720],
    },
    {
        type: "car",
        name: "standard",
        capacity: 24000,
        size: [245, 280, 1360],
    },
    {
        type: "car",
        name: "mega",
        capacity: 24000,
        size: [245, 300, 1360],
    },
];


export type IPosition = [number, number, number];

export type ISize = [number, number, number];

export type CanvasMode = 'banner' | 'autoposition' | 'resize' | 'rotation' | 'autostocking';
;

export const scalePosition = (position: IPosition): IPosition => [
    parseNumber(position[0] / 100),
    parseNumber(position[1] / 100),
    parseNumber(position[2] / 100),
];
export const parseNumber = (value: number, fraction = 5) => parseFloat(value.toFixed(fraction));
export const scaleSize = (size: ISize): ISize => [
    parseNumber(size[0] / 100),
    parseNumber(size[1] / 100),
    parseNumber(size[2] / 100),
];

export const defaultPackages: IPackage[] = [{
    "name": "Paleta Euro",
    "id": "1oqs7",
    "position": [-62.5, 50, 205],
    "weight": 25,
    "type": "type-8xv9t",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "4fxy4m",
    "position": [57.5, 50, 205],
    "weight": 25,
    "type": "type-8xv9t",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "ivakz",
    "position": [-62.5, 50, 125],
    "weight": 25,
    "type": "type-8xv9t",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "bw0hce",
    "position": [57.5, 50, 125],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "09ydyo",
    "position": [-62.5, 50, 45],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "qax58c",
    "position": [57.5, 50, 45],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "byr8m",
    "position": [-62.5, 50, -35],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "5lkvuh",
    "position": [57.5, 50, -35],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "k94mr8",
    "position": [-62.5, 50, -115],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "473hn",
    "position": [57.5, 50, -115],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "8cruvc",
    "position": [-62.5, 50, -195],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "kpfrv",
    "position": [57.5, 50, -195],
    "weight": 25,
    "type": "type-nmvrod",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "1n6zml",
    "position": [-62.5, 150, 205],
    "weight": 25,
    "type": "type-t79n9q",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "j3yx1g",
    "position": [57.5, 150, 205],
    "weight": 25,
    "type": "type-t79n9q",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "iy34q",
    "position": [-62.5, 150, 125],
    "weight": 25,
    "type": "type-t79n9q",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "gh8rna",
    "position": [57.5, 150, 125],
    "weight": 25,
    "type": "type-t79n9q",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "9ecnx",
    "position": [-62.5, 150, 45],
    "weight": 25,
    "type": "type-ialki7",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "9u6cv",
    "position": [57.5, 150, 45],
    "weight": 25,
    "type": "type-ialki7",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "7ejs0q",
    "position": [-62.5, 150, -35],
    "weight": 25,
    "type": "type-ialki7",
    "size": [120, 100, 80],
    "rotation": 0
}]


export const messyPackages: IPackage[] = [{
    "name": "Niestandardowa",
    "id": "5onsgb",
    "position": [188.61120211193213, 50, 143.2787221273705],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 90
}, {
    "name": "Niestandardowa",
    "id": "yrva9n",
    "position": [188.61120211193213, 50, -126.4408006705537],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 90
}, {
    "name": "Niestandardowa",
    "id": "pm9fkl",
    "position": [346.769494490818, 50, -269.0564238511718],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 90
}, {
    "name": "Niestandardowa",
    "id": "edjx4h",
    "position": [-223.01940231728858, 50, -281.84689716463595],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 0
}, {
    "name": "Niestandardowa",
    "id": "669jfs",
    "position": [60.989475486407855, 50, -319.24492585063985],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "6nap3u",
    "position": [-246.21044621371993, 50, -29.43285162898299],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "qlkif",
    "position": [296.11120211193213, 50, -16.721277872629514],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "w2b0ow",
    "position": [-219.3479341536071, 50, 279.0715619564388],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "evc21",
    "position": [-219.82527443423456, 50, -148.2822347375244],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "vk6r6i",
    "position": [-263.8544617387322, 50, 87.83376052660878],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}]

export const tidyPackages: IPackage[] = [{
    "name": "Niestandardowa",
    "id": "5onsgb",
    "position": [-2.5, 50, 197.5],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 0
}, {
    "name": "Niestandardowa",
    "id": "yrva9n",
    "position": [-2.5, 50, 102.5],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 0
}, {
    "name": "Niestandardowa",
    "id": "pm9fkl",
    "position": [-2.5, 50, 7.5],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 0
}, {
    "name": "Niestandardowa",
    "id": "edjx4h",
    "position": [-2.5, 50, -87.5],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 0
}, {
    "name": "Niestandardowa",
    "id": "669jfs",
    "position": [-2.5, 50, -182.5],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [240, 100, 95],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "6nap3u",
    "position": [-62.5, 150, 205],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "qlkif",
    "position": [57.5, 150, 205],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "w2b0ow",
    "position": [-62.5, 150, 125],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "evc21",
    "position": [57.5, 150, 125],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}, {
    "name": "Paleta Euro",
    "id": "vk6r6i",
    "position": [-62.5, 150, 45],
    "weight": 25,
    "type": "type-ud6xxr",
    "size": [120, 100, 80],
    "rotation": 0
}]

export const singlePackage: IPackage = {
    "name": "Niestandardowa",
    "id": "5onsgb",
    "position": [0, 50, 0],
    "weight": 25,
    "type": "type-p39mhd",
    "size": [120, 100, 80],
    "rotation": 0
}

export const stockedPackages: IPackage[] =
    [{
        "name": "Paleta Euro",
        "id": "o9qmlj",
        "position": [-62.5, 50, 205],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "pfrthx",
        "position": [57.5, 50, 205],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "1m1w35",
        "position": [-62.5, 50, 125],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "4lzbt",
        "position": [57.5, 50, 125],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "4453k6",
        "position": [-62.5, 50, 45],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "xmu8u",
        "position": [57.5, 50, 45],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "jgr4x9",
        "position": [-62.5, 50, -35],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "nbnvaj",
        "position": [57.5, 50, -35],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "ds2xkv",
        "position": [-62.5, 50, -115],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "pybyk",
        "position": [57.5, 50, -115],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "dv8qd8",
        "position": [-62.5, 50, -195],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "tlueid",
        "position": [57.5, 50, -195],
        "weight": 25,
        "type": "type-iinta",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "6cc04q",
        "position": [-62.5, 150, 205],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "158he",
        "position": [57.5, 150, 205],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "ucvn0o",
        "position": [-62.5, 150, 125],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "hn84t",
        "position": [57.5, 150, 125],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "5ymur4",
        "position": [-62.5, 150, 45],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "rebnbc",
        "position": [57.5, 150, 45],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "lg6vrq",
        "position": [-62.5, 150, -35],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "xi0c7",
        "position": [57.5, 150, -35],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "3si4dt",
        "position": [-62.5, 150, -115],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "ye7s6m",
        "position": [57.5, 150, -115],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "krflu",
        "position": [-62.5, 150, -195],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }, {
        "name": "Paleta Euro",
        "id": "a93ggg",
        "position": [57.5, 150, -195],
        "weight": 25,
        "type": "type-ztx9l",
        "size": [120, 100, 80],
        "rotation": 0
    }]

