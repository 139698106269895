import React, {useState} from "react";
import Truck from "./Truck/Truck";
import {CameraControls} from "./CameraControls/CameraControls";
import Package from "./Package/Package";
import Playground from "./Playground/Playground";
import {
    getColor,
    IPackage,
} from "./utils";

interface Props {
    packages: IPackage[],
    truckSize: [number, number, number]
}


const ThreeDView = ({packages, truckSize}: Props) => {
    const [useControls, setUseControls] = useState<boolean>(false);
    const handleCanvasClick = () => {
        setUseControls(true);
    }
    return (
        <Playground truckSize={truckSize} onClick={handleCanvasClick}>
            <Truck size={truckSize}/>
            {packages.map((p) => (
                <Package
                    key={p.id}
                    packageData={p}
                    color={getColor(p.type, packages)}
                />
            ))}
            {useControls && <CameraControls/>}
        </Playground>
    );
}

export default ThreeDView;
