import * as React from "react"
import cx from 'classnames';
import {
    Button,
} from "@mui/material";
import * as styles from './SignUpButton.module.scss';
import {useTranslation} from "react-i18next";

interface IProps {
    label?: string;
    className?: string;
}

export const SignUpButton = (props: IProps) => {
    const {t} = useTranslation();
    return (
        <div className={styles.SignUpButton}>
            <Button variant="contained" color="primary" href={'https://app.loggy.pl'}
                    sx={{width: {xs: '100%', md: 'auto'}}}
                    className={cx('sign-up-btn', props.className)}>
                {props.label || t('button.planLoad')}
            </Button>
        </div>
    )

}
