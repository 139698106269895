import * as React from "react"
import cx from 'classnames';
import {SignUpButton} from "../SignUpButton/SignUpButton";
import {Container, Grid} from "@mui/material";
import * as styles from './Banner.module.scss';
import BrowserWindow from "../BrowserWindow/BrowserWindow";
import Banner3DView from "../3DView/Banner3DView";
import {useTranslation} from "react-i18next";

const Banner = ({isHeaderFixed}) => {
    const {t} = useTranslation();
    return (
        <div className={cx(styles.Banner, {[styles.FixedHeader]: isHeaderFixed})} id={'product'}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid xs={12} md={12} className={cx(styles.BannerText)}>
                        <h3>
                            {t('banner.title1')} <span> {t('banner.title2')}</span>  {t('banner.title3')}
                        </h3>
                        <p>
                            {t('banner.subtitle')}
                        </p>
                        <SignUpButton className={styles.Button}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <Grid xs={12} md={10} className={cx(styles.DashboardScreen)}>
                        <BrowserWindow><Banner3DView/></BrowserWindow>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Banner;
