import {Canvas} from "@react-three/fiber";
import React from "react";
import {ISize} from "../utils";

interface Props {
    truckSize: ISize;
    children: any;
    onClick: () => void;
}

const Playground = ({truckSize, children, onClick}: Props) => {
    const cameraPosition = [4, 2.2, -3];
    // const rotation = [-2.5, 0.9, 2.5];
    const getGridProperties = () => [
        Math.round(truckSize[2] / 100 + 5),
        Math.round(truckSize[2] / 100 + 3) / 0.5,
        "white",
        "white",
    ];
    return (
        <Canvas camera={{position: cameraPosition}} data-testid={"canvas"} resize={{scroll: false}}>
            <axesHelper/>
            <mesh position={[0, 0, 0]} rotation-x={-Math.PI / 2} onClick={onClick}>
                <planeBufferGeometry attach="geometry"
                                     args={[truckSize[2] / 100 + 5, truckSize[2] / 100 + 5, 1]}/>
                <meshPhysicalMaterial attach="material" opacity={0} transparent={true} transparency={1}/>
            </mesh>
            <hemisphereLight intensity={0.35}/>
            <spotLight
                position={[5, 5, 5]}
                angle={0.3}
                penumbra={1}
                intensity={2}
                castShadow={true}
                shadow-mapSize-width={256}
                shadow-mapSize-height={256}
            />
            <gridHelper args={getGridProperties() as any}/>
            {children}
        </Canvas>
    );
};

export default Playground;
