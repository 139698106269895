import * as React from "react"
import * as styles from './BrowserWindow.module.scss';
import cx from 'classnames';
import {Menu} from "@mui/icons-material";

interface Props {
    transparent?: boolean
}

const BrowserWindow: React.FC<Props> = ({children, transparent = true}) => {
    return (
        <div className={styles.BrowserWindow}>
            <div className={styles.Toolbar}>
                <div className={styles.ActionButtons}>
                    <span className={cx(styles.ActionButton, styles.Close)}/>
                    <span className={cx(styles.ActionButton, styles.Minimize)}/>
                    <span className={cx(styles.ActionButton, styles.Maximize)}/>
                </div>
                <Menu/>
            </div>
            <div className={styles.Content}
                 style={{backgroundColor: transparent ? 'transparent' : '#383B44'}}>{children}</div>
        </div>
    )
}

export default BrowserWindow;
