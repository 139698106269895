import { useRef } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import React from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
extend({ OrbitControls });
export const CameraControls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  // @ts-ignore
  useFrame(() => controls.current.update());
  // @ts-ignore
  return <orbitControls ref={controls} args={[camera, domElement]} />;
};
