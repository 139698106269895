import React from "react";
import {getRotationProperties, IPackage, scalePosition, scaleSize} from "../utils";

interface IProps {
    packageData: IPackage;
    color?: string;
}

const Package = ({color, packageData}: IProps) => {
    const {position, size} = packageData;
    return (
        <group position={scalePosition(position)} rotation={getRotationProperties(packageData.rotation || 0)}>
            <mesh>
                <boxBufferGeometry attach="geometry" args={scaleSize(size)}/>
                <meshBasicMaterial wireframe={true} attach="material" color={"black"}/>
            </mesh>
            <mesh>
                <boxBufferGeometry attach="geometry" args={scaleSize(size)}/>
                <meshStandardMaterial
                    flatShading={true}
                    transparent={true}
                    // opacity={isSelected ? 0.7 : 1}
                    opacity={1}
                    attach="material"
                    color={color}
                />
            </mesh>
        </group>
    );
};
export default Package;
