// extracted by mini-css-extract-plugin
export var Header = "Header-module--Header--dL45E";
export var HeaderItem = "Header-module--HeaderItem--oxsnQ";
export var FixedHeader = "Header-module--FixedHeader--Zu9CS";
export var RightSeparator = "Header-module--RightSeparator--S1MMX";
export var Logo = "Header-module--Logo--zGbKy";
export var LeftSection = "Header-module--LeftSection--xLaC+";
export var Navigation = "Header-module--Navigation--SOIkK";
export var SignUpBtn = "Header-module--SignUpBtn--q46ev";
export var MainMenu = "Header-module--MainMenu--LDaod";
export var PageMenu = "Header-module--PageMenu--5h5y0";
export var MobileMenuOutline = "Header-module--MobileMenuOutline--jR+So";
export var MobileMenu = "Header-module--MobileMenu--h6PrM";
export var Buttons = "Header-module--Buttons--LGmTv";